@use "bulma/sass/utilities/mixins";
@use "bulma/sass/utilities/initial-variables" as iv;

// FIXME: overriding Bulma variables isn't working
$family-monospace: "JetBrains Mono", "Menlo", monospace;

html,
body {
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  font-size: 100%;
  letter-spacing: 0.03rem;
}

.bulma-loader-mixin {
  @include mixins.loader;
}

.repo-link {
  position: fixed;
  background: linear-gradient(45deg, iv.$black-bis 45%, iv.$black-ter 55%);
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  right: 0;
  top: 0;
  width: 4rem;
  height: 4rem;
  z-index: 1024;
  .ti {
    font-size: 1.5rem;
  }

  .icon {
    color: iv.$grey;
    transform: rotate(45deg) translate(1.75rem, -0.9rem);
    &:hover {
      color: iv.$white;
    }
  }
}

#left-column,
#right-column {
  padding: 1rem;
}

#left-column {
  background: linear-gradient(69deg, iv.$black-ter, iv.$black);
  position: fixed;
  left: 0;
  width: 38.2vw; // Approximately 1 / (1 + φ) of the viewport width
  height: 100vh;
  @include mixins.touch { // mobile and tablet
    position: relative;
    width: 100%;
    height: auto;
  }
}

#right-column {
  background-color: iv.$black-bis;
  position: absolute;
  right: 0;
  width: 61.8vw; // Approximately φ / (1 + φ) of the viewport width
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include mixins.touch { // mobile and tablet
    position: relative;
    width: 100%;
    margin-top: 0;
  }
}

#heading {
  z-index: 1024;
  margin-bottom: -2rem;
  @include mixins.touch {
    top: 2rem;
    .gravatar {
      transform: scale(0.9);
    }
  }
}

#about {
  margin-top: 2rem;
  margin-bottom: 4rem;
  padding: 2rem;
  padding-bottom: 0;
  // width: calc(100% - 2rem);
  @include mixins.touch {
    margin-bottom: 3rem;
  }
}

#social {
  padding-bottom: 2rem;
  width: calc(66% - 2rem);
  .icon {
    font-size: 1.25rem;
  }
  @include mixins.touch {
    display: flex;
    justify-content: center;
    .button {
      width: 100%;
    }
  }
}

section > p,
.container > p { // indent paragraphs
  text-indent: 1.25rem;
}

.level,
.level-left,
.level-right {
  margin: 3rem 2rem -2rem 2rem;
  padding: 0;
}

.field {
  margin-bottom: 1rem;
}

.button {
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
  transition: all 0.2s iv.$easing;
  .icon, .text {
    transition: color 0.2s iv.$easing;
  }
  &:hover {
    background: iv.$black-ter;
  }
}

// show brand colors on hover
.btn-mastodon:hover {
  color: #6364f6;
}

// .btn-github:hover {
//   background: #1B2028;
// }

.btn-itch:hover {
  color: #FA5C5C;
}

.btn-stackoverflow:hover {
  color: #F48024;
}

.btn-buymeacoffee:hover {
  // color:#E9BE4B;
  color: #f9de4b;
}

.gravatar {
  padding: 1rem;
  transform: scale(1);
  transition: all 0.2s iv.$easing;
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s iv.$easing;
  }
}

.title.is-5 { // section titles
  margin-bottom: 1rem;
}

.tag {
  font-family: $family-monospace; // "JetBrains Mono", "Menlo", monospace;
}

.card {
  --bulma-card-shadow: none; // remove hideous default shadow
  border: 1px solid iv.$grey-darker;
  .icon {
    font-size: 1.5rem;
  }
}

.card-header {
  background-color: iv.$grey-darker;
  // border-bottom: 1px solid iv.$grey-darker;
}

.card-header-icon .icon {
  transition: color 0.2s iv.$easing;
  &:hover {
    color: iv.$white;
  }
}

.message .icon {
  font-size: 1.5rem;
}
